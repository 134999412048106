import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class TokenSendService implements HttpInterceptor {

  constructor(
  //  public myapi: CommonService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isCookies = navigator.cookieEnabled;
    console.log("iScookies:", isCookies);
    var token = this.getCookie("token");
    if (!isCookies) token = "none";
    console.log("intercept token:",token);
    let tokenaizeReq = req.clone({
      setHeaders: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + token
      }
    })
    //console.log("intercept tokenaizeReq:", tokenaizeReq);
    return next.handle(tokenaizeReq);
  }
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
