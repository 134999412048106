import { HttpResponseBase } from '@angular/common/http'; // ignore
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export class WebApiBase {

    constructor() { }

    protected transformOptions(options: any) {
        // do stuff here
        return Promise.resolve(options);
    }

    protected transformResult(
        url: string,
        response: HttpResponseBase,
        processor: (response: HttpResponseBase) => any): Observable<any> {
        // do stuff here
        return processor(response);
    }

    protected getBaseUrl(url?: string): string {
        return environment.serverUrl;
    }
}
