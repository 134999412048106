import { Injectable, SimpleChanges } from '@angular/core';
import { TOAST_MSG_DEFAULT_TIME } from '../app.consts';
import { Location } from '@angular/common';
import { BooksControllerService, DafYomiDTO, TablesTextsAndDrawsDTO } from './WebApi.service.generated';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isAdmin: boolean = true;

  // toast message
  toastTimer: any;
  toastMessage: ToastMessage;

  // daf yomi
  clientDate: string;
  isLoadDafYomi: boolean = false;
  dafYomi: DafYomiDTO = new DafYomiDTO();

  constructor(
    private booksCtrl: BooksControllerService,
    private location: Location,
    private navCtrl: NavController
  ) {
    console.log("CommonService constructor");
    this.getDafYomiParams();
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes[this.clientDate]) {
  //     console.log('clientDate changed:', changes[this.clientDate].currentValue);
  //   }
  // }
  
  getCurrDate() {
    const toDay = new Date();
    const dd = String(toDay.getDate());
    const MM = String(toDay.getMonth() + 1); //January is 0!
    const yyyy = toDay.getFullYear();
    const homeLastDate = yyyy + "-" + MM + "-" + dd;
    return homeLastDate;
  }
  getClientDate() {
    const toDay = new Date();
    const dd = String(toDay.getDate());
    const MM = String(toDay.getMonth() + 1); //January is 0!
    const yyyy = toDay.getFullYear();
    const homeLastDate = yyyy + "-" + MM + "-" + dd;
    this.clientDate = MM + "/" + dd + "/" + yyyy;
    console.log("getClientDate",homeLastDate);
    this.setCookie("homeLastDate", homeLastDate, 100);
  }

  getDafYomiParams(): boolean {
    this.getClientDate();
    this.isLoadDafYomi = true;
    this.booksCtrl.getDafYomiHeadPage(this.clientDate).subscribe(resp => {
      this.dafYomi = resp;
      this.isLoadDafYomi = false;
      console.log('getDafYomiHeadPage: ', this.dafYomi);
    }, err => {
      this.isLoadDafYomi = false;
      console.log('getDafYomiHeadPage error: ', err);
    });
    return true;
  }

  markSearch(text, pattern) {
    if (!pattern || (pattern == '') || !text) {
      return text;
    }
    let escRe = pattern.replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
    let regex = RegExp(escRe, 'gi');
    let replacement = '<strong>$&</strong>';
    return text.replace(regex, replacement);
  }
  //setToken(token) {

  //  this.setCookie("token", token, 1000);
  //}
  getToken() {
    return (this.getCookie("token"));
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    //console.log("setCookie ", cname + "=" + cvalue + ";" + expires + ";path=/");
  }
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  saveLocalStorage(key,value){
    localStorage.setItem(key, value);
  }
  getLocalStorage(key){
    return localStorage.getItem(key)
  }

  /** toast messages */
  private createToastMsg(toastObj: ToastMessage) {
    if (!toastObj.Message || !toastObj.Message.length) {
      return;
    }
    if (this.toastTimer != null) {
      clearTimeout(this.toastTimer);
    }
    this.toastMessage = toastObj;
    this.toastTimer = setTimeout(() => {
      this.toastMessage = null;
    }, toastObj.Duration);
  }

  createErrorToastMsg(str: string, time: number = TOAST_MSG_DEFAULT_TIME, msgParam: any = null) {
    this.createToastMsg({
      Code: -1,
      BgColor: '#ED2760',
      Duration: time,
      Icon: 'close-circle',
      Message: str,
      MsgParam: { value: msgParam }
    });
  }

  createInfoToastMsg(str: string, time: number = TOAST_MSG_DEFAULT_TIME, msgParam: any = null) {
    this.createToastMsg({
      Code: -1,
      BgColor: '#45BEFF',
      Duration: time,
      Icon: 'information-circle',
      Message: str,
      MsgParam: { value: msgParam }
    });
  }

  createWarnToastMsg(str: string, time: number = TOAST_MSG_DEFAULT_TIME, msgParam: any = null) {
    this.createToastMsg({
      Code: -1,
      BgColor: '#ff9933',
      Duration: time,
      Icon: 'warning',
      Message: str,
      MsgParam: { value: msgParam }
    });
  }

  createSuccessToastMsg(str: string, time: number = TOAST_MSG_DEFAULT_TIME, msgParam: any = null) {
    this.createToastMsg({
      Code: -1,
      BgColor: '#50D2C2',
      Duration: time,
      Icon: 'checkmark',
      Message: str,
      MsgParam: { value: msgParam }
    });
  }
  goToHome() {
    this.navCtrl.navigateBack(['/home/']);
  }
  goToHHome() {
    this.navCtrl.navigateBack(['/hh/']);
  }
  backToPage() {
    this.location.back();
  }
}

class ToastMessage {
  /** unused */
  Code: number;
  /** format: '#XXXXXX' */
  BgColor: string;
  /** duration time that the message will show on screen in miliseconds */
  Duration: number;
  /** the fa icon on the toast, format: ['fas'/'far', <fa icon name>] */
  Icon: string;
  /** the translate object that will show on the message */
  Message: string;
  /** parameter in translated string if exist */
  MsgParam: { value: string };
}


export class LastPages {


  constructor(

  ) {

  }
  saveLastPages(urls: string[], names: string) {
    // localStorage.removeItem('lastPages');
    var lastPages, lastPagesInd, tmpInd;
    var tmp = JSON.parse(localStorage.getItem('lastPages'));
    tmp = this.removeSameLastPages(urls);
    if (!tmp) {
      lastPages = [];
      lastPages.push(lastPage);
    }
    else {
      lastPages = tmp;
      if (tmp.length < 10)
        lastPages.push(lastPage);
      for (var i = tmp.length - 1; i > 0; i--) {
        lastPages[i] = tmp[i - 1];
      }
    }
    lastPages[0] = { urls: urls, names: names };
    localStorage.setItem('lastPages', JSON.stringify(lastPages));
    console.log("saveLastPages", lastPages);
  }
  removeSameLastPages(urls: string[]) {
    var tmp1 = JSON.parse(localStorage.getItem('lastPages'));
    var tmp2 = [];
    if (!tmp1) return;
    for (var i = 0; i < tmp1.length; i++) {
      var t1 = urls.slice(0, -1);
      var t2 = tmp1[i].urls.slice(0, -1);
      if (!(t1.join("!") == t2.join("!"))) tmp2.push(tmp1[i]);
    }
    return tmp2;
  }

  getLastPages() {
    var tmp = JSON.parse(localStorage.getItem('lastPages'));
    return tmp;
  }

}
export class lastPage {
  urls: string[];
  names: string[];
}