import { Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage)
  },
  {
    path: 'tree-editor',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2/:parm3',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2/:parm3/:parm4',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2/:parm3/:parm4/:parm5',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7/:parm8',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'tree-editor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7/:parm8/:parm9',
    loadComponent: () => import('./pages/tree-editor/tree-editor.page').then(m => m.TreeEditorPage)
  },
  {
    path: 'contentnew/:parm1',
    loadComponent: () => import('./pages/contentnew/contentnew.page').then(m => m.ContentnewPage)
  },
  {
    path: 'contentnew/:parm1/:parm2',
    loadComponent: () => import('./pages/contentnew/contentnew.page').then(m => m.ContentnewPage)
  },
  {
    path: 'contentnew/:parm1/:parm2/:parm3',
    loadComponent: () => import('./pages/contentnew/contentnew.page').then(m => m.ContentnewPage)
  },
  {
    path: 'contentnew/:parm1/:parm2/:parm3/:parm4',
    loadComponent: () => import('./pages/contentnew/contentnew.page').then(m => m.ContentnewPage)
  },
  {
    path: 'contentnew/:parm1/:parm2/:parm3/:parm4/:parm5',
    loadComponent: () => import('./pages/contentnew/contentnew.page').then(m => m.ContentnewPage)
  },
  {
    path: 'texts',
    loadComponent: () => import('./pages/texts/texts.page').then(m => m.TextsPage)
  },
  {
    path: 'tableditor/:parm1/:parm2',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'tableditor/:parm1/:parm2/:parm3',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'tableditor/:parm1/:parm2/:parm3/:parm4',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'tableditor/:parm1/:parm2/:parm3/:parm4/:parm5',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'tableditor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'tableditor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'tableditor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7/:parm8',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'tableditor/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7/:parm8/:parm9',
    loadComponent: () => import('./pages/tableditor/tableditor.page').then(m => m.TableditorPage)
  },
  {
    path: 'drawer/:parm1/:parm2',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'drawer/:parm1/:parm2/:parm3',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'drawer/:parm1/:parm2/:parm3/:parm4',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'drawer/:parm1/:parm2/:parm3/:parm4/:parm5',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'drawer/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'drawer/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'drawer/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7/:parm8',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'drawer/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7/:parm8/:parm9',
    loadComponent: () => import('./pages/drawer/drawer.page').then(m => m.DrawerPage)
  },
  {
    path: 'lines',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines/:parm1',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines/:parm1/:parm2',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines/:parm1/:parm2/:parm3',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines/:parm1/:parm2/:parm3/:parm4',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines/:parm1/:parm2/:parm3/:parm4/:parm5',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },

  {
    path: 'lines-edit',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines-edit/:parm1/:parm2',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines-edit/:parm1/:parm2/:parm3',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines-edit/:parm1/:parm2/:parm3/:parm4',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines-edit/:parm1/:parm2/:parm3/:parm4/:parm5',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines-edit/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'lines-edit/:parm1/:parm2/:parm3/:parm4/:parm5/:parm6/:parm7',
    loadComponent: () => import('./pages/lines/lines.page').then(m => m.LinesPage)
  },
  {
    path: 'edit-page-links/:docID',
    loadComponent: () => import('./pages/edit-page-links/edit-page-links.page').then(m => m.EditPageLinksPage)
  },
  {
    path: 'hh/:pageId',
    loadComponent: () => import('./pages/hh/hh.page').then(m => m.HhPage)
  },
  {
    path: 'hh',
    loadComponent: () => import('./pages/hh/hh.page').then(m => m.HhPage)
  },
  {
    path: 'hh-editor/:pageId',
    loadComponent: () => import('./pages/hh-editor/hh-editor.page').then(m => m.HhEditorPage)
  },
  {
    path: 'hh-map',
    loadComponent: () => import('./pages/hh-map/hh-map.page').then(m => m.HhMapPage)
  },
  {
    path: 'map-for-editor',
    loadComponent: () => import('./pages/hh-map/hh-map.page').then(m => m.HhMapPage)
  },
  {
    path: 'home-en',
    loadComponent: () => import('./pages/home-en/home-en.page').then(m => m.HomeEnPage)
  },
  {
    path: 'line-search',
    loadComponent: () => import('./pages/line-search/line-search.page').then(m => m.LineSearchPage)
  },
  {
    path: 'parameters',
    loadComponent: () => import('./pages/parameters/parameters.page').then(m => m.ParametersPage)
  },
  {
    path: 'similars',
    loadComponent: () => import('./pages/similars/similars.page').then(m => m.SimilarsPage)
  },
  {
    path: 'word2vec',
    loadComponent: () => import('./pages/word2vec/word2vec.page').then(m => m.Word2vecPage)
  },
  {
    path: 'statistics',
    loadComponent: () => import('./pages/statistics/statistics.page').then(m => m.StatisticsPage)
  },
  {
    path: 'tables-day',
    loadComponent: () => import('./pages/tables-day/tables-day.page').then(m => m.TablesDayPage)
  },
  {
    path: 'users-log',
    loadComponent: () => import('./pages/users-log/users-log.page').then(m => m.UsersLogPage)
  },
  
  
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'links',
    loadComponent: () => import('./pages/links/links.page').then( m => m.LinksPage)
  },

]