import { Component } from '@angular/core';
import { IonicModule, MenuController } from '@ionic/angular';
import { CommonService } from './services/common.service';
// import {addIcons} from 'ionicons'
// import {close,menu} from 'ionicons/icons'
import { ManageControllerService, MenuLineDTO } from './services/WebApi.service.generated';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IonApp, IonIcon, IonMenu, IonRouterOutlet } from '@ionic/angular/standalone';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule]
})
export class AppComponent {
  menus: MenuLineDTO[];
  constructor(
    public myapi: CommonService,
    private menuCtrl: MenuController,
    private router: Router,
    private manageCtrl: ManageControllerService,
    private activatedRoute: ActivatedRoute,
  ) {
    // addIcons({
    //   close,
    //   menu
    // })
    this.initializeApp();
  }



  initializeApp() {
    this.GetMenu();
  }

  route(link) {
    console.log("link-0", link);
    if (link.indexOf("lines-edit") > -1) {
      var url = window.location.pathname;
      console.log("link-1", url);
      if (url.indexOf("\/lines\/") > -1) {
        url = url.replace("lines", "lines-edit");
        console.log("link-2", url);
        link = url;
      }
    }
    this.router.navigateByUrl(link);
    this.toggleMenu();
  }

  toggleMenu() {
    console.log("toggle");
    this.menuCtrl.toggle('first');
  }

  GetMenu() {
    this.manageCtrl.getMenu().subscribe({
      next: resp => {
        this.menus = resp.list;
        console.log('GetMenu : ', resp);
        this.myapi.setCookie("token", resp.token, 1000);
        this.menuCtrl.enable(true, 'first');
      }, error: err => {
        console.log('GetMenu ERROR: ', err);
      }
    });
  }
}
